import ai from "../Website Screenshots/Jasper.png";
import ai1 from "../Website Screenshots/Surfer.png";
import ai2 from "../Website Screenshots/Article Forge.png";
import ai3 from "../Website Screenshots/Word AI.png";
import ai4 from "../Website Screenshots/INK For All.png";
import ai5 from "../Website Screenshots/Outranking.png";
import ai6 from "../Website Screenshots/Rytr.png";
import ai7 from "../Website Screenshots/AI Writer.png";
import ai8 from "../Website Screenshots/Writesonic.png";
import ai9 from "../Website Screenshots/MarketMuse.png";
import ai10 from "../Website Screenshots/Copy.AI.png";
import ai11 from "../Website Screenshots/Paragraph AI.png";
import ai12 from "../Website Screenshots/Texta.png";
import ai13 from "../Website Screenshots/CopyGenius.png";
import ai14 from "../Website Screenshots/GrowthBar.png";
import ai15 from "../Website Screenshots/CopyMatic.png";
import ai16 from "../Website Screenshots/Shakespeare.png";
import ai17 from "../Website Screenshots/text.cortex.png";
import ai18 from "../Website Screenshots/Content Forge.png";
import ai19 from "../Website Screenshots/Wordhero.png";
import ai20 from "../Website Screenshots/Copysmith.png";
import ai21 from "../Website Screenshots/Bertha.png";
import ai22 from "../Website Screenshots/Content at Scale.png";
import ai23 from "../Website Screenshots/Kafkai.png";
import ai24 from "../Website Screenshots/Yaara.png";
import ai25 from "../Website Screenshots/Quizgecko.png";
import ai26 from "../Website Screenshots/PromptPal.png";
import ai27 from "../Website Screenshots/LinkDelta.png";
import ai29 from "../Website Screenshots/FormWise.png";
import ai30 from "../Website Screenshots/Podium.png";
import ai31 from "../Website Screenshots/Tugan AI.png";
import ai32 from "../Website Screenshots/Wordmax.png";
import ai33 from "../Website Screenshots/relume.PNG";
import ai34 from "../Website Screenshots/Embolden.png";
import ai35 from "../Website Screenshots/Blog Assistant.png";
import ai36 from "../Website Screenshots/Right Blogger.png";
import ai37 from "../Website Screenshots/recapiogpt.PNG"; 
import ai38 from "../Website Screenshots/Write Panda.png";
import ai39 from "../Website Screenshots/Copy Monkey.png";
import ai40 from "../Website Screenshots/Resume Check.png";
import ai41 from "../Website Screenshots/Eilla AI.png";
import ai42 from "../Website Screenshots/Taskade.png";
import ai43 from "../Website Screenshots/At Once.png";
import ai44 from "../Website Screenshots/Sudowrite.png";
import ai45 from "../Website Screenshots/Decktopus.png";
import ai46 from "../Website Screenshots/Super Meme.png";
import ai47 from "../Website Screenshots/Neuron Writer.png";
import ai48 from "../Website Screenshots/Melville.png";
//AI video
import ai49 from "../Website Screenshots/Opus clip.png";
import ai50 from "../Website Screenshots/GetMunch.png";
import ai51 from "../Website Screenshots/Specterr.png";
import ai52 from "../Website Screenshots/Synthesia.png";
import ai53 from "../Website Screenshots/Nova AI.png";
import ai54 from "../Website Screenshots/Wave Video.png";
import ai55 from "../Website Screenshots/DeepBrain AI.png";
import ai56 from "../Website Screenshots/Colossyan.png";
import ai57 from "../Website Screenshots/Opus clip.png";
import ai58 from "../Website Screenshots/Magic Slides.png";
//AI Image
import ai59 from "../Website Screenshots/Pictory.png";
import ai599 from "../Website Screenshots/ShortPixel.png";
import ai60 from "../Website Screenshots/EWWW.png";
import ai61 from "../Website Screenshots/CANVA.png";
import ai62 from "../Website Screenshots/BannerBear.png";
import ai63 from "../Website Screenshots/AI Art Shop.png";
import ai64 from "../Website Screenshots/Dotyeti.png";
import ai65 from "../Website Screenshots/Icons8.png";
import ai66 from "../Website Screenshots/Virtual Staging AI.png";
import ai67 from "../Website Screenshots/Pro Photos AI Headshot.png";
import ai68 from "../Website Screenshots/Turbologo.png";
import ai69 from "../Website Screenshots/Baked AI.png";
import ai70 from "../Website Screenshots/Visualize AI.png";
import ai71 from "../Website Screenshots/Avatarize.png";
import ai72 from "../Website Screenshots/Midjourney.png";
import ai73 from "../Website Screenshots/Palette FM.png";
import ai74 from "../Website Screenshots/HairStyle AI.png";
//Ai Audio
import ai75 from "../Website Screenshots/Speak AI.png";
import ai76 from "../Website Screenshots/Podcastle.png";
import ai77 from "../Website Screenshots/Chipbot.png";
import ai78 from "../Website Screenshots/Rythmex.png";
import ai79 from "../Website Screenshots/Castmagic.io.png";
import ai80 from "../Website Screenshots/11ElevenLabs.png";
import ai81 from "../Website Screenshots/Play HT.png";
import ai82 from "../Website Screenshots/Verbatik.png";
import ai83 from "../Website Screenshots/Adobe Enhance speech.png";
import ai84 from "../Website Screenshots/Amper Music.png";
import ai85 from "../Website Screenshots/Voicemod.png";
import ai86 from "../Website Screenshots/Speechify.png";
//SEO Tools
import ai87 from "../Website Screenshots/Alli AI.png";
import ai88 from "../Website Screenshots/GetGenie.png";
import ai89 from "../Website Screenshots/Ranked.png";
import ai90 from "../Website Screenshots/Data Slayer.png";
import ai91 from "../Website Screenshots/Testing.AI.png";
import ai92 from "../Website Screenshots/Sincode.png";
import ai93 from "../Website Screenshots/Custom GPT.png";
import ai94 from "../Website Screenshots/Simple Phones.png";
//Ai Social Media
import ai95 from "../Website Screenshots/Devi AI.png";
import ai96 from "../Website Screenshots/Octane AI.png";
import ai97 from "../Website Screenshots/Go Charlie.png";
import ai98 from "../Website Screenshots/PostWise.png";
import ai99 from "../Website Screenshots/Tweet Hunter.png";
//ChatBot
import ai100 from "../Website Screenshots/MagicForm.png";
import ai101 from "../Website Screenshots/Botnation.png";
import ai102 from "../Website Screenshots/My AI Front Desk.png";
import ai103 from "../Website Screenshots/Chat Fast.png";
import ai104 from "../Website Screenshots/Crear.ai.png";
import ai105 from "../Website Screenshots/BotSheets.png";
import ai106 from "../Website Screenshots/Chatbot Kit.png";
import ai107 from "../Website Screenshots/PromptBox.png";
import ai108 from "../Website Screenshots/Resolve AI.png";
import ai109 from "../Website Screenshots/Chatgpt.png";
//Data Search
import ai110 from "../Website Screenshots/GAJIX.png";
import ai111 from "../Website Screenshots/Webscrape AI.png";
import ai112 from "../Website Screenshots/Humata.png";
import ai113 from "../Website Screenshots/Sheet AI.png";
//Web Development & Design
import ai114 from "../Website Screenshots/mixo.PNG";
//Creative and Productivity Enhancement
import ai115 from "../Website Screenshots/Bard .png";
import ai116 from "../Website Screenshots/Adobe Firefly.png";
import ai117 from "../Website Screenshots/Adobe Sensei.png";
//Media and Entertainment
import ai118 from "../Website Screenshots/DALL-E 3.png";
//Business
import ai119 from "../Website Screenshots/Humantic AI.png";
import ai142 from "../Website Screenshots/Gentext.png";
import ai143 from "../Website Screenshots/Pod.png";
import ai144 from "../Website Screenshots/Salesforce AI.png";
import ai145 from "../Website Screenshots/Ticket Genius.png";
import ai146 from "../Website Screenshots/Buffer.png";
import ai147 from "../Website Screenshots/Flick.png";
import ai148 from "../Website Screenshots/Simplified.png";
import ai149 from "../Website Screenshots/Paddle.png";
import ai150 from "../Website Screenshots/FirstLineGPT.png";
import ai151 from "../Website Screenshots/Warp.png";
import ai152 from "../Website Screenshots/Content at Scale.png";
import ai153 from "../Website Screenshots/creative.PNG"; 
import ai154 from "../Website Screenshots/AskSumo.png";
//Education
import ai120 from "../Website Screenshots/Huru AI.png";
import ai121 from "../Website Screenshots/Khan Labs.png";
import ai123 from "../Website Screenshots/CheggMate.png";
import ai122 from "../Website Screenshots/Tomorrow.png";
import ai124 from "../Website Screenshots/WolframAlpha.png";
import ai125 from "../Website Screenshots/Eightify.png";
import ai126 from "../Website Screenshots/Mathly.png";
import ai127 from "../Website Screenshots/Caktus.png";
import ai128 from "../Website Screenshots/Prospre.png";
//writing
import ai1299 from "../Website Screenshots/Grammarly.png";
import ai129 from "../Website Screenshots/DeepL.png";
import ai130 from "../Website Screenshots/Quillbot.png";
import ai131 from "../Website Screenshots/Notion.png";
import ai132 from "../Website Screenshots/Language Tool.png";
import ai133 from "../Website Screenshots/Evelyn.png";
import ai134 from "../Website Screenshots/GPTZero.png";
import ai135 from "../Website Screenshots/Wordtune.png";
import ai136 from "../Website Screenshots/Gamma.png";
import ai137 from "../Website Screenshots/Sharly.png";
import ai138 from "../Website Screenshots/Novel AI.png";
import ai139 from "../Website Screenshots/Write.Homes.png";
import ai140 from "../Website Screenshots/AISEO.png";
import ai141 from "../Website Screenshots/GoodAI.png";
//inovactions
import ai155 from "../Website Screenshots/Character AI.png";
import ai156 from "../Website Screenshots/Midjourney.png";
import ai157 from "../Website Screenshots/Helper AI.png";
import ai158 from "../Website Screenshots/Jobscan.png";
import ai159 from "../Website Screenshots/Copyleaks.png";
import ai160 from "../Website Screenshots/FakeYou.png";
import ai161 from "../Website Screenshots/FaceCheck.ID.png";
import ai162 from "../Website Screenshots/Undetectable.png";
//Design
import ai163 from "../Website Screenshots/Remove bg.png";
import ai164 from "../Website Screenshots/Fotor.png";
import ai165 from "../Website Screenshots/Designer.png";
import ai166 from "../Website Screenshots/Contentinator.png";
import ai167 from "../Website Screenshots/Kapwing.png";
import ai168 from "../Website Screenshots/heritage.PNG";//not avalible
import ai169 from "../Website Screenshots/namecheap.PNG";
import ai170 from "../Website Screenshots/Venngage.png";
import ai171 from "../Website Screenshots/Looka.png";
import ai172 from "../Website Screenshots/Teal HQ.png";
import ai1722 from "../Website Screenshots/Befunky.png";
import ai173 from "../Website Screenshots/Nightcafe.png";
import ai174 from "../Website Screenshots/Playground.png";
import ai175 from "../Website Screenshots/Craiyon.png";
import ai176 from "../Website Screenshots/Auto Draw.png";
import ai1766 from "../Website Screenshots/PhotoSonic.png";
import ai177 from "../Website Screenshots/Designs AI.png";
import ai178 from "../Website Screenshots/Publer.png";
import ai179 from "../Website Screenshots/Runway.png";
import ai180 from "../Website Screenshots/whatfonts.png";
import ai181 from "../Website Screenshots/PicWish.png";
import ai182 from "../Website Screenshots/Remini.png";
import ai183 from "../Website Screenshots/Kittl.png";
import ai184 from "../Website Screenshots/Edit at Scale.png";
import ai185 from "../Website Screenshots/LogoAI.png";
import ai1855 from "../Website Screenshots/Erase It.png";
import ai186 from "../Website Screenshots/Artguru.png";
import ai187 from "../Website Screenshots/FyStudio.png";
import ai189 from "../Website Screenshots/Leonardo AI.png";
import ai190 from "../Website Screenshots/Artbreeder.png";
//Coding
import ai193 from "../Website Screenshots/Replit.png";
import ai194 from "../Website Screenshots/CodeAssist.png";
//query
import ai195 from '../Website Screenshots/Casetext.png'
//video
import ai196 from '../Website Screenshots/Veed.png'
import ai197 from '../Website Screenshots/Descript.png'
import ai198 from '../Website Screenshots/Topaz Labs Video.png'
import ai199 from '../Website Screenshots/VidIQ.png'
import ai200 from '../Website Screenshots/Animaker.png'
import ai201 from '../Website Screenshots/Murf.png'
import ai202 from '../Website Screenshots/Synthesia.png'
import ai203 from '../Website Screenshots/Uberduck.png'
import ai204 from '../Website Screenshots/Facetune.png'
import ai205 from '../Website Screenshots/HitPaw.png'
import ai206 from '../Website Screenshots/TinyWow.png'
import ai207 from '../Website Screenshots/DeepSwap.png'
//audio
import ai208 from '../Website Screenshots/11ElevenLabs.png'
import ai209 from '../Website Screenshots/Riverside.png'
//mix
import ai210 from '../Website Screenshots/GetHuman.png'
import ai211 from '../Website Screenshots/Songtell.png'
import ai212 from '../Website Screenshots/Otter.png'
import ai213 from '../Website Screenshots/Crystals.png'
import ai214 from '../Website Screenshots/Topaz Labs Photo.png'
import ai215 from '../Website Screenshots/Meal Planner.png'
import ai216 from '../Website Screenshots/Landr.png'
import ai217 from '../Website Screenshots/Klaviyo.png'
import ai218 from '../Website Screenshots/Roast.png'
import ai219 from '../Website Screenshots/Resume Worded.png'
import ai220 from '../Website Screenshots/Civitai.png'
import ai221 from '../Website Screenshots/DoNotPay.png'
import ai222 from '../Website Screenshots/Namelix.png'
import ai223 from '../Website Screenshots/Replika.png'




const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const initialCardsData = shuffle([
  {
    image: ai,
    alt: "Jasper",
    title: "Jasper",
    description:
      "Jasper is an AI platform tailored for businesses to boost their brand identity. It's ideal for generating fresh ideas or adapting existing ones into various styles or languages.    ",
    link: "https://jasper.ai/",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai1,
    alt: "Surfer",
    title: "Surfer",
    description:
      "Surfer SEO, known as Surfer, enhances search engine rankings for content managers and writers by automating SEO steps.    ",
    link: "https://surferseo.com/",
    category: "Writing",
    rating: 5,
    price: "Paid",
    cost: "$$$",
  },
  {
    image: ai2,
    alt: "Article Forge",
    title: "Article Forge    ",
    description:
      "Article Forge is a fast program that generates topic-specific articles, saving time and money in content creation.    ",
    link: "https://www.articleforge.com/",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai3,
    alt: "Word AI",
    title: "Word AI",
    description:
      "WordAi rewrites content using advanced AI, producing versions indistinguishable from human writing.    ",
    link: "https://wordai.com/?ref=1820ai",
    category: "Writing",
    rating: 3,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai4,
    alt: "INK For All",
    title: "INK For All    ",
    description:
      "INK speeds up content creation, improving search engine rankings with its patented AI technology.    ",
    link: "https://inkforall.com/",
    category: "Writing",
    rating: 5,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai5,
    alt: "Outranking IO",
    title: "Outranking IO    ",
    description:
      "Outranking combines AI for content writing, SEO strategy planning, and brief creation, enhancing website rankings.    ",
    link: "https://www.outranking.io/",
    category: "Writing",
    rating: 0,
    price: "Paid",
    cost: "$$",
  },
  {
    image: ai6,
    alt: "Rytr",
    title: "Rytr",
    description:
      "Rytr, an AI tool, produces quality content quickly and affordably, streamlining the writing process.    ",
    link: "https://rytr.me/",
    category: "Writing",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai7,
    alt: "AI Writer",
    title: "AI Writer",
    description:
      "AI-Writer generates accurate, reliable articles using cutting-edge AI, with verifiable information.    ",
    link: "https://ai-writer.com/?via=chase68https://ai-writer.com/?via=chase68",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai8,
    alt: "Writesonic",
    title: "Writesonic",
    description:
      "Writesonic is an AI-driven platform for unlimited content generation, streamlining content creation with user inputs.    ",
    link: "https://writesonic.com/",
    category: "Writing",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai9,
    alt: "Market Muse",
    title: "Market Muse",
    description:
      "MarketMuse uses AI, trend data, and SEO strategies to enhance content for creators and marketers.    ",
    link: "https://www.marketmuse.com/",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$$$",
  },
  {
    image: ai10,
    alt: "Copy AI",
    title: "Copy AI",
    description:
      "Copy.ai generates high-quality marketing copy with AI technology, offering a free, no-credit-card trial.    ",
    link: "https://www.copy.ai/",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai11,
    alt: "Paragraph AI",
    title: "Paragraph AI",
    description:
      "ParagraphAI writes and automatically responds to emails using AI, simplifying and speeding up writing tasks.    ",
    link: "https://paragraphai.com/",
    category: "Writing",
    rating: 5,
    price: "Trial",
    cost: "$ ",
  },
  {
    image: ai12,
    alt: "Texta AI    ",
    title: "Texta AI    ",
    description:
      "Texta.ai creates engaging, optimized content for blogs, websites, and social media with AI technology.    ",
    link: "https://www.texta.ai/",
    category: "Writing",
    rating: 5,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai13,
    alt: "CopyGenius",
    title: "CopyGenius",
    description:
      "Copygenius AI quickly and easily writes and edits digital content with a single click.    ",
    link: "https://copygenius.io/",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai14,
    alt: "GrowthBar    ",
    title: "GrowthBar    ",
    description:
      "GrowthBar, highly rated on G2, is an all-in-one AI tool for SEO content research, writing, and optimization.    ",
    link: "https://growthbarseo.com/",
    category: "Writing",
    rating: 5,
    price: "Trial",
    cost: "$$$",
  },
  {
    image: ai15,
    alt: "Copymatic    ",
    title: "Copymatic    ",
    description:
      "Copymatic.ai, an AI tool, quickly generates high-quality content for bloggers, creators, and marketers.    ",
    link: "https://copymatic.ai/",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai16,
    alt: "Shakespeare AI    ",
    title: "Shakespeare AI    ",
    description:
      "Shakespeare AI creates blog content efficiently, maximizing space in product pages and online ads.    ",
    link: "https://shakespeare.ai/",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$$$",
  },
  {
    image: ai17,
    alt: "Textcortex AI    ",
    title: "Textcortex AI    ",
    description:
      "TextCortex blends Natural Language Generation with marketing practices for effective AI copywriting.    ",
    link: "https://textcortex.com/",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai18,
    alt: "Contentforge AI    ",
    title: "Contentforge AI    ",
    description:
      "ContentForge, using AI, assists in producing high-quality content for websites, blogs, and social media.    ",
    link: "https://contentforge.ai/",
    category: "Writing",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai19,
    alt: "WordHero",
    title: "WordHero",
    description:
      "WordHero is an Writing tool that assists business owners, marketers, and writers in creating high-quality content quickly and efficiently.    ",
    link: "https://wordhero.co/",
    category: "Writing",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai20,
    alt: "CopySmith AI    ",
    title: "CopySmith AI    ",
    description:
      "Copysmiths AI powered content generator assists marketing and eCommerce teams to create exceptional content with ease. You can start for free, and there is no limit to the amount of high-quality content you can produce",
    link: "http://copysmith.ai/",
    category: "Writing",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai20,
    alt: "CrawlQ AI",
    title: "CrawlQ AI",
    description:
      "CrawlQ AI is a conversational AI platform based in the US that provides market research and analysis for founders, marketers, and copywriters.    ",
    link: "https://crawlq.ai/",
    category: "Writing",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai21,
    alt: "Bertha AI",
    title: "Bertha AI",
    description:
      "Bertha AI is an AI-powered content generator that creates exclusive content and images for your WordPress site.",
    link: "https://bertha.ai/",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai22,
    alt: "Content at Scale",
    title: "Content at Scale",
    description:
      "Content at Scale leverages successful strategies for your target keywords to boost your ranking on Google by creating content based on that data.    ",
    link: "https://contentatscale.ai/",
    category: "Writing",
    rating: 0,
    price: "Paid",
    cost: "$$",
  },
  {
    image: ai23,
    alt: "Kafkai    ",
    title: "Kafkai    ",
    description:
      "Kafkai is an AI-powered article generator that produces creative and unique content with a focus on SEO optimization.    ",
    link: "https://kafkai.com/",
    category: "Writing",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai24,
    alt: "Yaara    ",
    title: "Yaara    ",
    description:
      "Yaara.ai: The AI-powered content generation tool that accelerates content creation by 3X for businesses. Boost productivity and efficiency with Yaara.ai.    ",
    link: "https://yaara.ai/",
    category: "Writing",
    rating: 4,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai25,
    alt: "Quizgecko    ",
    title: "Quizgecko    ",
    description:
      "Quizgecko is an AI-powered quiz creation tool that can be used by teachers, students, and businesses. It can generate quizzes from text, files, and even YouTube videos.    ",
    link: "https://quizgecko.com/",
    category: "Writing",
    rating: 0,
    price: "Paid",
    cost: "$$",
  },
  {
    image: ai26,
    alt: "Prompt Pal    ",
    title: "Prompt Pal    ",
    description:
      "PromptPal is an AI-powered tool that generates text, translates languages, writes creative content and answers questions. Its easy t ouse, affordable and effective for improving writing skills for both businesses and individuals.    ",
    link: "https://promptpal.net/",
    category: "Writing",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai27,
    alt: "Linkdelta",
    title: "Linkdelta",
    description:
      "Linkdelta is an affordable and user-friendly Writing tool that generates informative and engaging content for blog posts, articles, social media posts, and more. It allows you to customize the generated content according to your specific needs in just a few minutes.    ",
    link: "https://app.linkdelta.com/",
    category: "Writing",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai29,
    alt: "Form Wise",
    title: "Form Wise",
    description:
      "With Formwise.ai, users can easily generate text, translate languages and write creative content for marketing copy or blog posts while improving writing skills and productivity with its user-friendly interface and seamless integration with other tools.    ",
    link: "https://www.formwise.ai/",
    category: "Writing",
    rating: 0,
    price: "Paid",
    cost: "$$",
  },
  {
    image: ai30,
    alt: "Podium",
    title: "Podium",
    description:
      "Podium is an AI-powered tool perfect for podcasters, bloggers, and content creators who need to produce high-quality content quickly. With its transcription, text generation, and outline creation features, Podium helps users save time while improving the quality of their work and reaching a wider audience.",
    link: "https://hello.podium.page/",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai31,
    alt: "Tugan AI",
    title: "Tugan AI",
    description:
      "Tugan.ai, an AI-powered tool, can generate text, translate languages, create various types of creative content and answer queries. With Tugan.ai, users can save time by automating the writing process for improved quality and reach a wider audience with accessible content.",
    link: "https://www.tugan.ai/",
    category: "Writing",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai32,
    alt: "Wordmax",
    title: "Wordmax",
    description:
      "Wordmax AI Writer generates unique and original content for various purposes such as blog posts, website copy, and social media posts. With features like writing templates, article generator wizard and the ability to generate up to 200 words in under 30 seconds, it helps save time while improving the quality of your content.",
    link: "https://wordmax.ai/",
    category: "Writing",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai33,
    alt: "Relume Ipsum",
    title: "Relume Ipsum",
    description:
      "Relume Ipsum is a writing tool that uses GPT-3 to generate website copy based on a brief company description. It allows designers and developers to quickly populate website wireframes with realistic content.",
    link: "https://www.relumeipsum.com/",
    category: "Writing",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai34,
    alt: "Embolden",
    title: "Embolden",
    description:
      "Embolden.co uses AI to produce persuasive, informative and creative content for businesses, including product descriptions, emails, social media posts and ad copy. The tool saves time and money on content creation by generating high-quality output that improves website SEO, boosts conversion rates and sets the firm apart from its rivals.",
    link: "https://embolden.co/",
    category: "Writing",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai35,
    alt: "Blog Assistant",
    title: "Blog Assistant",
    description:
      "Blog Assistant is an AI tool that produces SEO-friendly content in minutes, providing features such as blog length selection, keyword suggestions and image inclusion. It also delivers plagiarism-free content at an affordable cost-per-blog.",
    link: "https://www.blogassistant.co/",
    category: "Writing",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai36,
    alt: "Right Blogger",
    title: "Right Blogger",
    description:
      "RightBlogger is an AI-powered writing tool that creates various types of content for bloggers, including generating titles, outlines, and introductions based on keywords. Its a time saving and customizable solution for creating high quality blog posts with ease.",
    link: "https://rightblogger.com/",
    category: "Writing",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai37,
    alt: "Recapio GPT",
    title: "Recapio GPT",
    description:
      "RecapioGPT can summarize various forms of text such as articles, books and blog posts making it a valuable tool for researchers, students and anyone requiring concise summaries in a short amount of time.",
    link: "https://app.recapiogpt.com/",
    category: "Writing",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai38,
    alt: "Write Panda",
    title: "Write Panda",
    description:
      "WritePanda is an AI-powered writing tool that allows users to create professional copy for various types of content, including blog posts and email marketing campaigns. It is a valuable resource for those looking to enhance their writing skills and increase productivity.",
    link: "https://www.writepanda.ai/",
    category: "Writing",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai39,
    alt: "Copy Monkey",
    title: "Copy Monkey",
    description:
      "CopyMonkey is an artificial intelligence-powered writing tool that creates marketing copy such as product descriptions and bullet points. It offers features like SEO optimization, feedback from experts, and performance tracking for businesses to improve their marketing efforts with ease.",
    link: "https://copymonkey.ai/",
    category: "Writing",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai40,
    alt: "Resume Check",
    title: "Resume Check",
    description:
      "Resumecheck.net is a platform that assists in enhancing users resumes by identifying errors and typos, offering recommendations on how to optimize it for specific job roles and industries, as well as generating new sections that can be added. The tool offers personalized adjustments including grammar corrections, skills refinement, experience correction and resume structure adjustment.",
    link: "https://resumecheck.net/",
    category: "Writing",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai41,
    alt: "Eilla AI",
    title: "Eilla AI",
    description:
      "Eilla AI is an AI assistant that can assist you in a range of tasks, including writing blog posts and articles, creating social media content and ad copies, translating languages, generating creative content like poems or scripts. With Eilla AIs support, users can save time on their writing projects while also enhancing their writing skills.",
    link: "https://eilla.ai/",
    category: "Writing",
    rating: 5,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai42,
    alt: "Taskade",
    title: "Taskade",
    description:
      "Taskade is a versatile tool that facilitates collaborative task management, note-taking, file sharing and communication among team members. With real-time collaboration features, task management tools, note-taking capabilities and chat functions, it helps teams stay organized and productive.",
    link: "https://taskade.com/",
    category: "Writing",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai43,
    alt: "At Once",
    title: "At Once",
    description:
      "AtOnce is an Writing tool that uses artificial intelligence to generate original and keyword-rich content quickly. You can write blog posts, social media posts, emails, and more while improving your writing style and grammar with its tracking features.",
    link: "https://atonce.com/",
    category: "Writing",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai44,
    alt: "Sudowrite",
    title: "Sudowrite",
    description:
      "Sudowrite is an AI tool for fast and effortless high-quality content creation, suitable for various formats. With its AI technology, Sudowrite can generate plagiarism-free original content with the right keywords while also enhancing your writing style and grammar.",
    link: "https://www.sudowrite.com/",
    category: "Writing",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai45,
    alt: "Decktopus",
    title: "Decktopus",
    description:
      "Decktopus is an AI-powered presentation tool with a wide variety of templates and customization options, as well as collaboration and easy sharing features. Its a great option for anyone looking to create stunning presentations quickly and easily.",
    link: "https://www.decktopus.com/",
    category: "Writing",
    rating: 4,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai46,
    alt: "Supermeme ai",
    title: "Supermeme ai",
    description:
      "Supermeme.ai is an AI-powered tool with a wide variety of templates and customization options for creating funny and engaging memes, which can be easily shared via email, social media or link.",
    link: "https://supermeme.ai/",
    category: "Writing",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai47,
    alt: "Neuron Writer",
    title: "Neuron Writer",
    description:
      "NeuronWriter enables users to research high-ranking content and generate structured drafts using AI, improving the speed and quality of their writing process. It also allows for team collaboration and is a valuable tool for enhancing website SEO.",
    link: "https://neuronwriter.com/",
    category: "Writing",
    rating: 4,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai48,
    alt: "Melville",
    title: "Melville",
    description:
      "Melville is a writing tool that helps you write better content, faster. It uses artificial intelligence to generate ideas, outlines, and even entire pieces of content for you. Melville can also help you improve your grammar, style, and clarity.",
    link: "https://usemelville.com/",
    category: "Writing",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  //AI Video
  {
    image: ai49,
    alt: "Opus Clip",
    title: "Opus Clip",
    description:
      "Opus Clip redefines video repurposing, offering a time-efficient way to create engaging short clips.",
    link: "https://clip.opus.pro/",
    category: "Video",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai50,
    alt: "Get Munch",
    title: "Get Munch",
    description:
      "Munch uses generative AI and analytics to extract engaging clips from long videos, enhancing their impact.",
    link: "https://www.getmunch.com/",
    category: "Video",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai51,
    alt: "Specterr",
    title: "Specterr",
    description:
      "Start creating stunning visual music videos for free and quickly see your ideas transformed into reality.",
    link: "https://specterr.com/",
    category: "Video",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai52,
    alt: "Synthesia",
    title: "Synthesia",
    description:
      "Harness AI for easy video creation from text, revolutionizing content creation and audience engagement.",
    link: "https://www.synthesia.io/",
    category: "Video",
    rating: 4,
    price: "Paid",
    cost: "$$",
  },
  {
    image: ai53,
    alt: "Nova AI",
    title: "Nova AI",
    description:
      "Discover new capabilities with automatic subtitling, translation, and transcription for enhanced video creation.",
    link: "https://app.wearenova.ai/",
    category: "Video",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai54,
    alt: "Wave Video",
    title: "Wave Video",
    description:
      "This platform combines Live Streaming Studio, Video Editor, Thumbnail Maker, Video Hosting, Recording, and Stock Library.",
    link: "https://wave.video/",
    category: "Video",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai55,
    alt: "DeepBrainp",
    title: "DeepBrain",
    description:
      "DeepBrain AI simplifies video production with AI avatars, ideal for marketing, education, and entertainment, saving time and resources.",
    link: "https://www.deepbrain.io/",
    category: "Video",
    rating: 0,
    price: "Paid",
    cost: "$$",
  },
  {
    image: ai56,
    alt: "Colossyan",
    title: "Colossyan",
    description:
      "Colossyan.com offers an AI video generator for quick, professional-quality videos with templates, customization, and social media sharing.",
    link: "https://www.colossyan.com/",
    category: "Video",
    rating: 4,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai57,
    alt: "UGC Scripts Creators",
    title: "UGC Scripts Creators",
    description:
      "UGC Scripts uses OpenAI to create high-performing video scripts, aiding creators, brands, and agencies in content development.",
    link: "https://ugcscripts.com/",
    category: "Video",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai58,
    alt: "Magic Slides App",
    title: "Magic Slides App",
    description:
      "MagicSlides.app speeds up presentation creation with customization options, supporting PowerPoint and Google Slides export, useful for students, educators, and professionals. ",
    link: "https://www.magicslides.app/",
    category: "Video",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  //AI Image Tools
  {
    image: ai59,
    alt: "Pictory",
    title: "Pictory",
    description:
      "Pictory enables quick creation of Video Sales Letters with stock footage, music, and voiceovers, aimed at maximizing conversions.",
    link: "https://pictory.ai/",
    category: "Image",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai599,
    alt: "Short Pixel",
    title: "Short Pixel",
    description:
      "ShortPixel efficiently optimizes images, CSS, and JS online, offering fast performance with global CDN delivery.",
    link: "https://shortpixel.com/",
    category: "Image",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai60,
    alt: "EWWW",
    title: "EWWW",
    description:
      "EWWW IO optimizes images using server tools (jpegtran, optipng, etc.) with an exclusive plugin.",
    link: "https://ewww.io/",
    category: "Image",
    rating: 4,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai61,
    alt: "Canva",
    title: "Canva",
    description:
      "Canva, a free online tool, simplifies stunning visual design for social media, presentations, and more.",
    link: "https://www.canva.com/",
    category: "Image",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai62,
    alt: "Bannerbear",
    title: "Bannerbear",
    description:
      "Bannerbear automates social media visuals and ecommerce banners creation with its API and integrations.",
    link: "https://www.bannerbear.com/",
    category: "Image",
    rating: 0,
    price: "Paid",
    cost: "$$",
  },
  {
    image: ai63,
    alt: "AI Art Shop",
    title: "AI Art Shop",
    description:
      "AI Art Shop offers a vast collection of AI-created paintings, pushing the boundaries of artistic creativity.",
    link: "https://aiartshop.com/",
    category: "Image",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai64,
    alt: "DotYeti",
    title: "DotYeti",
    description:
      "DotYeti combines freelancers, agencies, and in-house designers on one platform for streamlined creative processes.",
    link: "https://www.dotyeti.com/",
    category: "Image",
    rating: 5,
    price: "Paid",
    cost: "$$$$",
  },
  {
    image: ai65,
    alt: "Icon 8",
    title: "Icon 8",
    description:
      "A versatile icon design marketplace with extensive style choices, easy customization, and seamless software integration.",
    link: "https://icons8.com/",
    category: "Image",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai66,
    alt: "Virtual Staging AI",
    title: "Virtual Staging AI",
    description:
      "Virtual Staging AI stages real estate virtually using AI, enhancing property selling potential with efficiency and affordability.",
    link: "https://www.virtualstagingai.app/",
    category: "Image",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai67,
    alt: "Pro Photos AI",
    title: "Pro Photos AI",
    description:
      "ProPhotos.ai generates AI-powered professional headshots for LinkedIn, resumes, and other professional uses.",
    link: "https://prophotos.ai/",
    category: "Image",
    rating: 0,
    price: "Paid",
    cost: "$$",
  },
  {
    image: ai68,
    alt: "Turbologo",
    title: "Turbologo",
    description:
      "Turbologo provides an AI-driven, easy, affordable logo creation service with a vast library of icons and fonts.",
    link: "https://turbologo.com/",
    category: "Image",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai69,
    alt: "Baked AI",
    title: "Baked AI",
    description:
      "Baked AI creates custom product images from user ideas, suitable for mugs, phone cases, and t-shirts.",
    link: "https://www.baked-ai.com/",
    category: "Image",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai70,
    alt: "Visualize AI",
    title: "Visualize AI",
    description:
      "VisualizeAI creates realistic images from sketches, photos, or text, useful in product design, architecture, and interior design.",
    link: "https://visualizeai.pro/",
    category: "Image",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai71,
    alt: "Avatarize",
    title: "Avatarize",
    description:
      "Avatarize.club generates realistic AI images for social media profiles and marketing materials, with customization options.",
    link: "https://avatarize.club/",
    category: "Image",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai72,
    alt: "Midjourney",
    title: "Midjourney",
    description:
      "Midjourney is a research lab exploring AI image generation and expanding human imagination.",
    link: "https://www.midjourney.com/",
    category: "Image",
    rating: 4,
    price: "Free",
    cost: "$$",
  },
  {
    image: ai73,
    alt: "Palette fm",
    title: "Palette fm",
    description:
      "Palette.fm colorizes black and white pictures with an AI-powered, user-friendly image editor.",
    link: "https://palette.fm/",
    category: "Image",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai74,
    alt: "Hairstyle AI",
    title: "Hairstyle AI",
    description:
      "Hairstyle AI offers a virtual hairstyling experience, allowing users to try different hairstyles and colors on their photos.",
    link: "https://www.hairstyleai.com/",
    category: "Image",
    rating: 0,
    price: "Paid",
    cost: "$",
  },

  //AI Audio Tools
  {
    image: ai75,
    alt: "Speak AI",
    title: "Speak AI",
    description:
      "Speak Ai converts audio, video, and text into competitive insights with transcription and NLP, enhancing marketing and research with data-driven intelligence.",
    link: "https://speakai.co/",
    category: "Audio",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai76,
    alt: "Podcastle",
    title: "Podcastle",
    description:
      "Podcastle offers a simple solution for creating professional-quality podcasts, with easy recording and editing features.",
    link: "https://podcastle.ai/",
    category: "Audio",
    rating: 5,
    price: "Free",
    cost: "$",
  },
  {
    image: ai77,
    alt: "ChipBot",
    title: "ChipBot",
    description:
      "Chip elevates Discord server audio with high-quality, uninterrupted music and 24/7 uptime.",
    link: "https://getchipbot.com/",
    category: "Audio",
    rating: 5,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai78,
    alt: "Rythmex",
    title: "Rythmex",
    description:
      "Rythmex is an online tool for transcribing audio and video files to text, serving students, professionals, and those seeking to save time and increase productivity.",
    link: "https://rythmex.com/",
    category: "Audio",
    rating: 5,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai79,
    alt: "Castmagic io",
    title: "Castmagic io",
    description:
      "Castmagic.io aids podcasters by generating transcripts, show notes, and social media clips with timestamped chapters, plus custom prompts for unique content creation.",
    link: "https://www.castmagic.io/",
    category: "Audio",
    rating: 4,
    price: "Paid",
    cost: "$$",
  },
  {
    image: ai80,
    alt: "ElevenLabs",
    title: "ElevenLabs",
    description:
      "ElevenLabs specializes in advanced text-to-speech technology in the USA, producing natural-sounding speech synthesis.",
    link: "http://elevenlabs.io/",
    category: "Audio",
    rating: 5,
    price: "Free",
    cost: "$",
  },
  {
    image: ai81,
    alt: "Play.ht",
    title: "Play.ht",
    description:
      "Play.ht is an AI platform converting text to realistic speech, offering over 900 voices in 142 languages and customization options like pitch, speed, and added sound effects.",
    link: "https://www.play.ht/",
    category: "Audio",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai82,
    alt: "Verbatik",
    title: "Verbatik",
    description:
      "Verbatik, a text-to-speech tool, converts text into natural audio, useful for audiobooks, podcasts, and educational materials.",
    link: "https://tts.verbatik.com/",
    category: "Audio",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai83,
    alt: "Adobe Enhance Speech",
    title: "Adobe Enhance Speech",
    description:
      "Adobe Enhance Speech uses AI to improve the quality of spoken audio recordings.",
    link: "https://helpx.adobe.com/ca/premiere-pro/using/enhance-speech.html",
    category: "Audio",
    rating: 4,
    price: "Free",
    cost: "$$",
  },
  {
    image: ai84,
    alt: "Amper Music",
    title: "Amper Music",
    description:
      "Amper Musics AI based tools and API make music creation enjoyable and accessible.",
    link: "https://ampermusic.zendesk.com/hc/en-us",
    category: "Audio",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai85,
    alt: "Voicemod",
    title: "Voicemod",
    description:
      "Voicemod offers diverse voice transformations with unique effects, creating imaginative and enjoyable audio experiences.",
    link: "https://www.voicemod.net/",
    category: "Audio",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai86,
    alt: "Speechify",
    title: "Speechify",
    description:
      "Speechify offers a top-notch text-to-speech solution with renowned voices for Chrome, iOS, Android, and Mac, enhancing reading inclusivity and enjoyment.",
    link: "https://speechify.com/",
    category: "Audio",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  //SEO Tools
  {
    image: ai87,
    alt: "Alli AI",
    title: "Alli AI",
    description:
      "Alli AI regularly scans your site, providing fresh Recommendations based on content changes, with an option to refresh them on the dashboard.",
    link: "https://www.alliai.com/",
    category: "SEO",
    rating: 4,
    price: "Trial",
    cost: "$$$",
  },
  {
    image: ai88,
    alt: "GetGenie AI",
    title: "GetGenie AI",
    description:
      "GetGenie uses AI to write, optimize, research, and create original content, quickly generating output with minimal input.",
    link: "https://app.getgenie.ai/",
    category: "SEO",
    rating: 5,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai89,
    alt: "Ranked AI",
    title: "Ranked AI",
    description:
      "AI-powered SEO uses AI technologies to analyze keywords and site performance, advising on strategies to improve search rankings, leveraging algorithms like Google BERT and Rankbrain.",
    link: "http://ranked.ai/",
    category: "SEO",
    rating: 0,
    price: "Trial",
    cost: "$$$",
  },
  {
    image: ai90,
    alt: "Data Slayer AI",
    title: "Data Slayer AI",
    description:
      "DataSlayer enhances Chrome Developer Tools by adding a panel to monitor tag management data layers, presenting changes in a user-friendly format.",
    link: "https://dataslayer.ai/",
    category: "SEO",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai91,
    alt: "A/B Testing AI",
    title: "A/B Testing AI",
    description:
      "ABtesting.ai is an intuitive A/B testing platform that automatically suggests and executes tests on landing pages, requiring no prior A/B testing knowledge.",
    link: "https://abtesting.ai/",
    category: "SEO",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai92,
    alt: "SinCode AI",
    title: "SinCode AI",
    description:
      "SinCode AI generates content for various purposes, including blog and social media posts, marketing materials, language translation, and answering questions.",
    link: "https://sincode.ai/",
    category: "SEO",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai93,
    alt: "Custom GPT ai",
    title: "Custom GPT ai",
    description:
      "CustomGPT, using ChatGPT-4, creates chatbots for businesses with natural language responses, website integration, and performance tracking, applicable for writing, SEO, social media, and data search.",
    link: "https://customgpt.ai/",
    category: "SEO",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai94,
    alt: "Simple Phones ai",
    title: "Simple Phones ai",
    description:
      "SimplePhones.ai provides AI-based virtual phone assistance with call, text messaging, customer support, CRM integration, interaction tracking, and reporting, suitable for businesses seeking improved productivity and customer service.",
    link: "https://simplephones.ai/",
    category: "SEO",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  //AI Social Media Tools
  {
    image: ai95,
    alt: "Devi AI",
    title: "Devi AI ",
    description:
      "Devi, an AI-based platform, manages social media for Facebook groups, LinkedIn, Twitter, and Reddit, offering keyword monitoring, sentiment analysis, and automated outreach to enhance social media marketing.",
    link: "https://ddevi.com/",
    category: "Social Media",
    rating: 5,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai96,
    alt: "Octane AI ",
    title: "Octane AI ",
    description:
      "Octane AI is a tool designed to streamline and enhance the effectiveness of social media marketing for businesses.",
    link: "https://www.octaneai.com/",
    category: "Social Media",
    rating: 4,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai97,
    alt: "Go Charlie",
    title: "Go Charlie",
    description:
      "GoCharlie, an AI tool, quickly creates relevant content for social media, blogs, and websites, boosting engagement and saving time and money in content production.",
    link: "https://gocharlie.ai/",
    category: "Social Media",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai98,
    alt: "Postwise AI ",
    title: "Postwise AI ",
    description:
      "Postwise AI aids in writing, scheduling, and growing Twitter followings, valuable for businesses, organizations, and individuals aiming to expand their reach on Twitter.",
    link: "https://postwise.ai/",
    category: "Social Media",
    rating: 4,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai99,
    alt: "Tweet Hunter",
    title: "Tweet Hunter",
    description:
      "Tweet Hunter, an AI-powered tool, produces high-quality content, schedules tweets, offers analytics, and features like automatic retweets and replies for effective audience engagement and growth tracking on Twitter.",
    link: "https://tweethunter.io/",
    category: "Social Media",
    rating: 1,
    price: "Trial",
    cost: "$$",
  },
  //ChatBot
  {
    image: ai100,
    alt: "MagicForm AI",
    title: "MagicForm AI",
    description:
      "MagicForm is an AI-driven chatbot tool enhancing customer engagement and conversion on websites, revolutionizing how businesses interact with customers.",
    link: "https://www.magicform.ai/",
    category: "ChatBot",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai101,
    alt: "Botnation",
    title: "Botnation",
    description:
      "BOTNATION AI transforms customer service with AI-enabled chatbots, offering a cloud-based live chat solution for effortless enhancement of customer service operations.",
    link: "https://part.botnation.ai/",
    category: "ChatBot",
    rating: 5,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai102,
    alt: "My AI Front Desk",
    title: "My AI Front Desk",
    description:
      "My AI Front Desk is a user-friendly chatbot assisting businesses in customer service and operations, automating tasks like scheduling and inquiries, customizable and integrable with tools like Vagaro and Calendly.",
    link: "https://www.myaifrontdesk.com/",
    category: "ChatBot",
    rating: 0,
    price: "Paid",
    cost: "$$",
  },
  {
    image: ai103,
    alt: "ChatFast",
    title: "ChatFast",
    description:
      "ChatFast allows creation of GPT chatbots from personal data, capable of answering any questions and embeddable on websites.",
    link: "https://chatfast.io/",
    category: "ChatBot",
    rating: 5,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai104,
    alt: "Crear.ai",
    title: "Crear.ai",
    description:
      "Crear.ai provides chatbot and conversational AI solutions using natural language processing, enabling users to design and integrate chatbots across multiple channels without coding.",
    link: "https://crear.ai/?",
    category: "ChatBot",
    rating: 5,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai105,
    alt: "Botsheets",
    title: "Botsheets",
    description:
      "Botsheets lets users create and manage chatbots using Google Sheets, integrating with platforms like Facebook Messenger and Slack, featuring natural language processing, sentiment analysis, and machine learning.",
    link: "https://botsheets.com/",
    category: "ChatBot",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai106,
    alt: "Chat Bot Kit",
    title: "Chat Bot Kit",
    description:
      "ChatBotKit is a versatile platform for creating chatbots for various purposes, offering tools for customer service, sales, and more, with features like appointment booking and weather information.",
    link: "https://chatbotkit.com/",
    category: "ChatBot",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai107,
    alt: "Promptbox AI",
    title: "Promptbox AI",
    description:
      "Promptbox.ai, a Chrome extension, organizes AI prompts with features like right-click-direct-save and drag-and-drop, enhancing AI art creation.",
    link: "https://www.promptbox.ai/",
    category: "ChatBot",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai108,
    alt: "Resolve AI",
    title: "Resolve AI",
    description:
      "Resolve AI chatbot platform offers a drag-and-drop builder for easy chatbot creation, pre-trained models for tasks like customer support, and integrations with CRM, e-commerce, and marketing platforms.",
    link: "https://resolveai.co/",
    category: "ChatBot",
    rating: 5,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai109,
    alt: "ChatGPT",
    title: "ChatGPT",
    description:
      "ChatGPT enhances dialogue language models, elevating the accuracy and impact of chatbot responses.",
    link: "https://chat.openai.com/",
    category: "ChatBot",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  //Data Search
  {
    image: ai110,
    alt: "GAJIX",
    title: "GAJIX",
    description:
      "GAJIX is an AI assistant that utilizes advanced algorithms to analyze any subject or webpage and provide users with instant access to a vast amount of information for self-education. Users can input a topic or URL and receive comprehensive insights, making learning easier.",
    link: "https://gajix.com/",
    category: "Data",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai111,
    alt: "WebscrapeAi",
    title: "WebscrapeAi",
    description:
      "WebscrapeAi is a user-friendly and affordable tool that helps businesses extract data with high accuracy from various websites, including those with JavaScript and CAPTCHAs. It offers both free and paid plans, making it an excellent choice for any size business looking to save time and money while gaining valuable insights from data.",
    link: "https://webscrapeai.com/",
    category: "Data",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai112,
    alt: "Humata ai",
    title: "Humata ai",
    description:
      "Humata is a data search tool that uses AI to answer questions about your files. It can be used to research, learn, create reports, analyze legal documents, and understand technical papers.",
    link: "https://www.humata.ai/",
    category: "Data",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai113,
    alt: "Sheet AI ",
    title: "Sheet AI ",
    description:
      "SheetAI is an AI-powered add-on for Google Sheets that helps users automate tasks, generate insights, and create personalized content. It allows users to generate text, translate languages, write different kinds of creative content, and answer questions in an informative way.",
    link: "https://sheetai.app/",
    category: "Data",
    rating: 4,
    price: "Free",
    cost: "$",
  },

  //Web Development & Design
  {
    image: ai114,
    alt: "Mixo",
    title: "Mixo",
    description:
      "Transform your startup concept into reality instantly using Mixo AI driven no code website building platform.",
    link: "https://www.mixo.io/",
    category: "Web Design",
    rating: 5,
    price: "Trial",
    cost: "$",
  },
  //Creative and Productivity Enhancement
  {
    image: ai115,
    alt: "Google Bard",
    title: "Google Bard",
    description:
      "Bard is a creative ally, always accessible to boost your creativity, improve your productivity, and turn your ideas into tangible results.",
    link: "https://bard.google.com/",
    category: "Productivity",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai116,
    alt: "Adobe Firefly",
    title: "Adobe Firefly",
    description: "Adobe Firefly empowers creators with AI-generated designs.",
    link: "https://www.adobe.com/products/firefly.html",
    category: "Productivity",
    rating: 4,
    price: "Paid",
    cost: "$$",
  },
  {
    image: ai117,
    alt: "Adobe Sensei",
    title: "Adobe Sensei",
    description:
      "Adobe Sensei optimizes the creative workflow with the aid of artificial intelligence (AI) and machine learning.",
    link: "https://www.adobe.com/sensei.html",
    category: "Productivity",
    rating: 4,
    price: "Paid",
    cost: "$$",
  },
  //Media and Entertainment
  {
    image: ai118,
    alt: "DALL-E 3",
    title: "DALL-E 3",
    description:
      "DallE-2 generates diverse, realistic visuals by fusing different concepts and styles, based on written descriptions.",
    link: "https://openai.com/dall-e-3",
    category: "Media",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  //Business
  {
    image: ai119,
    alt: "Humantic AI",
    title: "Humantic AI",
    description:
      "Humantic AI is a Buyer Intelligence platform for revenue teams.",
    link: "https://app.humantic.ai/#/login?referral_code=Ryvvaliquette",
    category: "Business",
    rating: 5,
    price: "Trial",
    cost: "$$",
  },
  //Education
  {
    image: ai120,
    alt: "Huru",
    title: "Huru",
    description:
      "Huru is an AI-powered interview preparation app providing customized interviews, instant feedback, and a vast library of over 20,000 questions with answer guides. It also features video recording capabilities, aiming to enhance users communication skills and increase their chances of success in job interviews.",
    link: "https://huru.ai/",
    category: "Education",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai121,
    alt: "Khan Academy Khanmigo",
    title: "Khan Academy Khanmigo",
    description:
      "Khan Academy Khanmigo: a groundbreaking AI revolutionizing education by personalizing learning and transforming the educational experience.",
    link: "https://www.khanacademy.org/khan-labs",
    category: "Education",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai122,
    alt: "Tomorrow IO",
    title: "Tomorrow IO",
    description:
      "Tomorrow IO utilizes AI to provide accurate weather predictions, helping you optimize your daily plans.",
    link: "https://www.tomorrow.io/",
    category: "Education",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai123,
    alt: "CheggMate",
    title: "CheggMate",
    description:
      "CheggMate combines Cheggs extensive content library and expertise with GPT-4 advanced AI capabilities.",
    link: "https://www.cheggmate.ai/",
    category: "Education",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai124,
    alt: "WolframAlpha",
    title: "WolframAlpha",
    description:
      "WolframAlpha, an expert computational engine, offers valuable educational answers across numerous subjects.",
    link: "https://www.wolframalpha.com/",
    category: "Education",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai125,
    alt: "Eightify",
    title: "Eightify",
    description:
      "Eightify utilizes AI to offer brief YouTube video summaries, consolidating data into eight key concepts for improved learning.",
    link: "https://eightify.app/",
    category: "Education",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai126,
    alt: "Mathly",
    title: "Mathly",
    description:
      "Mathly is a smart math homework tool that provides customized explanations, improving the learning process and making it enjoyable.",
    link: "https://mathly.webflow.io/",
    category: "Education",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai127,
    alt: "Caktus",
    title: "Caktus",
    description:
      "Caktus offers AI solutions to improve students academic tasks, like essay writing, coding assistance, and job application support.",
    link: "https://caktus.ai/",
    category: "Education",
    rating: 1,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai128,
    alt: "Prospre",
    title: "Prospre",
    description:
      "Prospre creates personalized meal plans to cater to your individual nutritional needs.",
    link: "https://www.prospre.io/",
    category: "Education",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  //Writing
  {
    image: ai1299,
    alt: "Grammarly",
    title: "Grammarly",
    description:
      "Grammarly AIpowered tool enhances writing style and tone across platforms, guiding users to write confidently beyond grammar and spelling.",
    link: "https://www.grammarly.com/",
    category: "Writing",
    rating: 5,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai129,
    alt: "DeepL",
    title: "DeepL",
    description:
      "DeepL translator, driven by AI, efficiently bridges language gaps with fast and accurate translations.",
    link: "https://www.deepl.com/translator",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai130,
    alt: "Quillbot",
    title: "Quillbot",
    description:
      "Quillbot Paraphraser enhances writing by rephrasing and boosting originality for free.",
    link: "https://quillbot.com/",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai131,
    alt: "Notion AI",
    title: "Notion AI",
    description:
      "Notion AI improves the writing process by modifying pages and boosting productivity and creativity.",
    link: "https://www.notion.so/product",
    category: "Writing",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai132,
    alt: "LanguageTool",
    title: "LanguageTool",
    description:
      "LanguageTool improves writing by detecting and correcting errors in spelling, grammar, word choice, and style across 30+ languages, ensuring flawless quality.",
    link: "https://languagetool.org/",
    category: "Writing",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai133,
    alt: "EvelynAI",
    title: "EvelynAI",
    description:
      "EvelynAI combines GPT-3 language processing with Telegram to facilitate effortless content generation through messaging.",
    link: "https://t.me/EvelynxAI?ref=producthunt",
    category: "Writing",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai134,
    alt: "GPTZero",
    title: "GPTZero",
    description:
      "GPTZero is a simple AI tool for distinguishing between human-written and AI-generated texts.",
    link: "https://gptzero.me/",
    category: "Writing",
    rating: 5,
    price: "Free",
    cost: "$",
  },
  {
    image: ai135,
    alt: "Wordtune",
    title: "Wordtune",
    description:
      "Wordtune is a powerful Writing tool that improves content quality and increases reader engagement by rewriting and rephrasing it.",
    link: "https://www.wordtune.com/",
    category: "Writing",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai136,
    alt: "Gamma",
    title: "Gamma",
    description:
      "Gamma streamlines content creation by producing visually engaging and captivating material, eliminating the need for formatting.",
    link: "https://gamma.app/?lng=en",
    category: "Writing",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai137,
    alt: "Sharly AI",
    title: "Sharly AI",
    description:
      "The Chat Summary service simplifies reviewing and referencing important conversations through condensed transcripts.",
    link: "http://sharly.ai/",
    category: "Writing",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai138,
    alt: "NovelAI",
    title: "NovelAI",
    description:
      "This AI-powered writing service invigorates storytelling, offering writers a novel approach to crafting narratives.",
    link: "https://novelai.net/",
    category: "Writing",
    rating: 5,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai139,
    alt: "Write.homes",
    title: "Write.homes",
    description:
      "Write.homes is an ideal tool for creating appealing property descriptions that attract potential buyers, offering captivating real estate content.",
    link: "https://www.write.homes/",
    category: "Writing",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai140,
    alt: "AISEO",
    title: "AISEO",
    description:
      "AISEO is a smart writing tool that converts ideas into compelling, SEO-friendly content, perfect for busy professionals and writers.",
    link: "https://aiseo.ai/",
    category: "Writing",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai141,
    alt: "Good AI",
    title: "Good AI",
    description:
      "AI systems with high proficiency can generate impressive essays, drastically enhancing writing productivity.",
    link: "https://www.goodai.com/",
    category: "Writing",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  //Business
  {
    image: ai142,
    alt: "GenText AI Assistant for MS Word",
    title: "GenText AI Assistant for MS Word",
    description:
      "The GenText AI Assistant streamlines report writing in MS Word, boosting efficiency.",
    link: "https://gentext.ai/",
    category: "Business",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai143,
    alt: "PodAI",
    title: "PodAI",
    description:
      "PodAI utilizes a chatbot to provide personalized sleep assessments, helping improve sleep quality.",
    link: "https://www.pod.ai/",
    category: "Business",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai144,
    alt: "Salesforce AI",
    title: "Salesforce AI",
    description:
      "Einstein GPT harnesses customer data for actionable business insights, enhancing engagement and productivity.",
    link: "https://www.salesforceairesearch.com/",
    category: "Business",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai145,
    alt: "TicketGenius",
    title: "TicketGenius",
    description:
      "TicketGenius simplifies Jira ticket creation, boosting productivity.",
    link: "https://ticketgenius.ai/",
    category: "Business",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai146,
    alt: "Buffer",
    title: "Buffer",
    description:
      "Buffer creates engaging marketing strategies by generating creative ideas and developing promotional content to keep your approach captivating.",
    link: "Buffer",
    category: "Business",
    rating: 5,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai147,
    alt: "Flick",
    title: "Flick",
    description:
      "Flick boosts marketing content creation, optimizing engagement and expanding reach.",
    link: "https://www.flick.social/",
    category: "Business",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai148,
    alt: "Simplified",
    title: "Simplified",
    description:
      "Simplified AI Writer creates excellent content across multiple platforms, relieving you from the task of writing by efficiently producing high-quality material.",
    link: "https://simplified.com/",
    category: "Business",
    rating: 4,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai149,
    alt: "Paddle",
    title: "Paddle",
    description:
      "Paddle enhances financial processes for SaaS firms by offering comprehensive solutions for payments, taxes, and subscriptions, thereby improving business efficiency.",
    link: "https://www.paddle.com/ai-launchpad",
    category: "Business",
    rating: 4,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai150,
    alt: "First Line GPT",
    title: "First Line GPT",
    description:
      "GPT excels in crafting custom AI opening lines for B2B cold emails, fostering greater engagement and response rates.",
    link: "https://firstlinegpt.webflow.io/",
    category: "Business",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai151,
    alt: "Warp AI",
    title: "Warp AI",
    description:
      "Warp AI improves terminal usage by providing error explanations, suggesting solutions, generating scripts, and aiding with complex installations.",
    link: "https://www.warp.dev/warp-ai",
    category: "Business",
    rating: 0,
    price: "Free",
    cost: "$$$",
  },
  {
    image: ai152,
    alt: "Content At Scale",
    title: "Content At Scale",
    description:
      "Content At Scale uses AI to produce SEO-optimized content, enhancing online visibility and saving time.",
    link: "https://contentatscale.ai/",
    category: "Business",
    rating: 0,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai153,
    alt: "Creative Reality Studio (D-ID)",
    title: "Creative Reality Studio (D-ID)",
    description:
      "Using GPT-3, Stable Diffusion, and D-IDs face animation tech, the Creative Reality Studio swiftly transforms your ideas into talking avatars",
    link: "https://studio.d-id.com/",
    category: "Business",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai154,
    alt: "AskSumo",
    title: "AskSumo",
    description:
      "AskSumo uses AI chat to improve productivity and save costs by helping you find affordable software choices.",
    link: "https://appsumo.com/asksumo/",
    category: "Business",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  //Innovations
  {
    image: ai155,
    alt: "Character AI",
    title: "Character AI",
    description:
      "Using Character AI, you can create and interact with virtual characters, breathing life into them on an optimized platform for advanced conversational agents.",
    link: "https://beta.character.ai/",
    category: "Innovations",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai156,
    alt: "Midjourney",
    title: "Midjourney",
    description: "Midjourney, an AI-focused lab, pioneers imaginative platforms that stretch human creativity.",
    link: "https://www.midjourney.com/",
    category: "Innovations",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai157,
    alt: "Helper AI",
    title: "Helper AI",
    description: "Enhance your web browsing experience with the Helper AI that enables GPT-4 on any webpage.",
    link: "https://helperai.ai/",
    category: "Innovations",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai158,
    alt: "Jobscan",
    title: "Jobscan",
    description: "Jobscan, an AI-powered resume optimization tool, ensures your resume is tailored to job postings by including necessary keywords and work experience.",
    link: "http://www.jobscan.co/",
    category: "Innovations",
    rating: 5,
    price: "Free",
    cost: "$",
  },
  {
    image: ai159,
    alt: "Copyleaks",
    title: "Copyleaks",
    description: "Using AI-driven text analysis, Copyleaks protects and preserves the originality of your content.",
    link: "https://copyleaks.com/",
    category: "Innovations",
    rating: 4,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai160,
    alt: "FakeYou",
    title: "FakeYou",
    description: "FakeYou enables users to convert written text into spoken words, offering the ability to communicate using desired characters or even one's own voice replica.",
    link: "https://fakeyou.com/",
    category: "Innovations",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai161,
    alt: "FaceCheck ID",
    title: "FaceCheck",
    description: "FaceCheck ID ensures secure photo verification, protecting you and others reliably.",
    link: "https://facecheck.id/",
    category: "Innovations",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai162,
    alt: "Undetectable AI",
    title: "Undetectable AI",
    description: "The use of invisible AI tech enhances content marketing efficiency by generating undetectable AI content.",
    link: "https://undetectable.ai/",
    category: "Innovations",
    rating: 1,
    price: "Paid",
    cost: "$",
  },
  //Design
  {
    image: ai163,
    alt: "Remove.bg",
    title: "Remove.bg",
    description: "remove.bg is an advanced online tool that quickly eliminates photo backgrounds with AI precision for easy convenience.",
    link: "https://www.remove.bg/",
    category: "Design",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai164,
    alt: "Fotor",
    title: "Fotor",
    description: "Fotor's AI tech creates unique and customizable faces, adding artistic style to your projects.",
    link: "https://www.fotor.com/",
    category: "Design",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai165,
    alt: "Microsoft Designer",
    title: "Microsoft Designer",
    description: "The skilled Microsoft Designer rapidly produces remarkable designs from simple textual instructions.",
    link: "https://designer.microsoft.com/",
    category: "Design",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai166,
    alt: "Contentinator",
    title: "Contentinator",
    description: "Contentinator leverages AI for lifelike text and stunning imagery, enhancing design and content quality.",
    link: "https://contentinator.com/",
    category: "Design",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai167,
    alt: "Kapwing AI",
    title: "Kapwing AI",
    description: "Kapwing AI speeds up video creation through AI-driven tools, improving efficiency for editing or production.",
    link: "https://www.kapwing.com/ai",
    category: "Design",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai168,
    alt: "AI Time Machine",
    title: "AI Time Machine",
    description: "AI Time Machine brings the past to life with AI-generated images, enhancing your photos with a delightful touch.",
    link: "https://www.myheritage.com/",
    category: "Design",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai169,
    alt: "Namecheap's Logo Maker",
    title: "Namecheap's Logo Maker",
    description: "Namecheap's Logo Maker tool provides a range of logos for free download.",
    link: "https://www.namecheap.com/logo-maker/",
    category: "Design",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai170,
    alt: "Venngage",
    title: "Venngage",
    description: "Create unique Valentine's Day cards on Venngage by entering the recipient's details, preferences, and desired style.",
    link: "https://venngage.com/",
    category: "Design",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai171,
    alt: "Looka",
    title: "Looka",
    description: "Looka employs AI to help you design a logo and build a brand you love.",
    link: "https://looka.com/",
    category: "Design",
    rating: 3,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai172,
    alt: "Teal Resume Builder",
    title: "Teal Resume Builder",
    description: "Teal Resume Builder eases job hunting by providing templates and AI-generated suggestions for a standout resume.",
    link: "https://www.tealhq.com/tools/resume-builder",
    category: "Design",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai1722,
    alt: "Befunky",
    title: "Befunky",
    description: "Enhance visual content by modifying images, creating collages, and applying effects using Befunky's features like cropping, resizing, and batch editing.",
    link: "https://www.befunky.com/",
    category: "Design",
    rating: 4,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai173,
    alt: "NightCafe Studio",
    title: "NightCafe Studio",
    description: "NightCafe Studio, an advanced AI platform, creates stunning artworks.",
    link: "https://creator.nightcafe.studio/",
    category: "Design",
    rating: 0,
    price: "Free",
    cost: "$",
  },
 
  {
    image: ai174,
    alt: "Playground AI",
    title: "Playground AI",
    description: "Playground AI is a free, AI-driven online image creator perfect for generating diverse content like artwork, social media posts, and presentations.",
    link: "https://playgroundai.com/",
    category: "Design",
    rating: 5,
    price: "Free",
    cost: "$",
  },
  {
    image: ai175,
    alt: "Craiyon",
    title: "Craiyon",
    description: "Craiyon, an AI model, transforms abstract ideas into visual depictions, bringing shape to imaginative thinking.",
    link: "https://www.craiyon.com/",
    category: "Design",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai176,
    alt: "Autodraw",
    title: "Autodraw",
    description: "Autodraw is AI software that predicts and expedites drawing by accurately anticipating your intended sketches.",
    link: "",
    category: "Design",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai1766,
    alt: "Photosonic AI",
    title: "Photosonic AI",
    description: "Photosonic AI utilizes AI technology to produce unique digital artwork in various styles and dimensions.",
    link: "https://photosonic.pro/",
    category: "Design",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  
  {
    image: ai178,
    alt: "Publer AI Assist",
    title: "Publer AI Assist",
    description: "Publer AI Assist enhances brand's social media visibility with exceptional, visually appealing images.",
    link: "https://publer.io/features/ai-assist",
    category: "Design",
    rating: 5,
    price: "Free",
    cost: "$",
  },
  {
    image: ai179,
    alt: "Runwayml",
    title: "Runwayml",
    description: "Runwayml is advanced video editing software designed to enhance creative projects and increase their visibility.",
    link: "https://runwayml.com/",
    category: "Design",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai177,
    alt: "Designs AI",
    title: "Designs AI",
    description: "Designs AI enables fast and efficient creation of logos, videos, banners, and mockups in just two minutes, speeding up design production.",
    link: "https://designs.ai/en",
    category: "Design",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai180,
    alt: "What font is",
    title: "What font is",
    description: "The font identifier tool quickly analyzes images to help users locate any font and resolve uncertainties.",
    link: "https://www.whatfontis.com/",
    category: "Design",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai181,
    alt: "PicWish",
    title: "PicWish",
    description: "PicWish employs AI technology to facilitate simple photo editing, resulting in a seamless and effortless experience.",
    link: "https://picwish.com/",
    category: "Design",
    rating: 4,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai182,
    alt: "Remini",
    title: "Remini",
    description: "Remini employs AI tech for converting old media into HD and can also double the size of any image.",
    link: "https://remini.ai/",
    category: "Design",
    rating: 4,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai183,
    alt: "Kittl",
    title: "Kittl",
    description: "Kittl generates outstanding visuals from written descriptions to empower designers and elevate their expertise in creating merchandise designs and social media content.",
    link: "https://www.kittl.com/",
    category: "Design",
    rating: 5,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai184,
    alt: "Edit At Scale",
    title: "Edit At Scale",
    description: "Edit At Scale boosts your online store's appearance by enabling bulk editing for product visuals, revolutionizing product presentation.",
    link: "https://edit-at-scale.cloudinary.com/",
    category: "Design",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai1855,
    alt: "Erase It",
    title: "Erase It",
    description: "Rapidly remove unwanted elements from your images, for clear and uncluttered visuals.",
    link: "https://erase-it.cloudinary.com/",
    category: "Design",
    rating: 0,
    price: "Free",
    cost: "$$",
  },
  {
    image: ai185,
    alt: "LogoAI",
    title: "LogoAI",
    description: "LogoAI utilizes design best practices to create unique logo designs and branding templates for business cards, posters, flyers, and social media through artificial intelligence.",
    link: "https://www.logoai.com/",
    category: "Design",
    rating: 1,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai186,
    alt: "Artguru",
    title: "Artguru",
    description: "Artguru is there to help create your perfect AI avatars and portraits, adding a distinct touch to your online image.",
    link: "https://www.artguru.ai/",
    category: "Design",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai187,
    alt: "Fy! Studio",
    title: "Fy! Studio",
    description: "Fy! Studio makes your ideas into unique wall decorations, adding an artistic touch to your space.",
    link: "https://www.iamfy.co/studio",
    category: "Design",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai189,
    alt: "Leonardo AI",
    title: "Leonardo AI",
    description: "Leonardo AI revolutionizes creative projects with AI-powered assets and consistent artistic style.",
    link: "https://leonardo.ai/",
    category: "Design",
    rating: 1,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai190,
    alt: "Artbreeder",
    title: "Artbreeder",
    description: "Artbreeder revolutionizes AI-driven art creation, unlocking unimaginable possibilities for innovative artistic forms.",
    link: "https://www.artbreeder.com/",
    category: "Design",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  //Coding
  {
    image: ai193,
    alt: "Replit",
    title: "Replit",
    description: "Replit is a coding platform that offers an IDE, compiler, interpreter, and a Ghostwriter feature, allowing users to write and run code in",
    link: "https://replit.com/",
    category: "Coding",
    rating: 4,
    price: "Free",
    cost: "$$",
  },
  {
    image: ai194,
    alt: "CodeAssist",
    title: "CodeAssist",
    description: "CodeAssist is a programming AI chatbot that can create or modify code from simple explanations.",
    link: "https://www.codeassist.tech/",
    category: "Coding",
    rating: 0,
    price: "Free",
    cost: "$",
  },
 //Query
  {
    image: ai195,
    alt: "Casetext",
    title: "Casetext",
    description: "Casetext's advanced search technology uncovers critical legal cases, ensuring no key precedents are missed.",
    link: "https://casetext.com/",
    category: "Query",
    rating: 5,
    price: "Trial",
    cost: "$$$",
  },
  //Video
  {
    image: ai196,
    alt: "Veed.io",
    title: "Veed.io",
    description: "Veed.io, a professional cloud-based video suite, enables seamless recording, editing, and streaming of videos.",
    link: "https://www.veed.io/",
    category: "Video",
    rating: 4,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai197,
    alt: "Descript",
    title: "Descript",
    description: "Descript provides an all-encompassing platform for video and podcast creation, transcription, editing, collaboration, and distribution.",
    link: "https://www.descript.com/",
    category: "Video",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai198,
    alt: "Topaz Video AI",
    title: "Topaz Video AI",
    description: "Topaz Video AI efficiently leverages neural networks for video enhancements, including upscaling, deinterlacing, and motion interpolation.",
    link: "https://www.topazlabs.com/topaz-video-ai",
    category: "Video",
    rating: 4,
    price: "Paid",
    cost: "$$$",
  },
  {
    image: ai199,
    alt: "VidIQ",
    title: "VidIQ",
    description: "VidIQ, an AI-driven SaaS tool, aids YouTube creators with video topic discovery, tailored suggestions, view predictions, and advanced analytics.",
    link: "https://vidiq.com/",
    category: "Video",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai200,
    alt: "Animaker Subtitle Generator",
    title: "Animaker Subtitle Generator",
    description: "Animaker's AI-powered Subtitle Generator creates subtitles rapidly, making videos more accessible.",
    link: "https://www.animaker.com/subtitle-generator",
    category: "Video",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai201,
    alt: "Murf AI",
    title: "Murf AI",
    description: "Murf AI offers lifelike AI voices to studio-level voice actors for high-quality voiceover recordings.",
    link: "http://murf.ai/",
    category: "Video",
    rating: 5,
    price: "Free",
    cost: "$",
  },
  {
    image: ai202,
    alt: "Synthesia",
    title: "Synthesia",
    description: "Synthesia creates lifelike AI videos using text scripts, eliminating the need for filming or actors, allowing for professional-quality videos.",
    link: "http://www.synthesia.io/",
    category: "Video",
    rating: 4,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai203,
    alt: "Uberduck",
    title: "Uberduck",
    description: "Uberduck offers over 5,000 unique voices, customizable duplicates, and audio APIs for easy voice-over and AI rap production.",
    link: "http://www.synthesia.io/",
    category: "Video",
    rating: 0,
    price: "Free",
    cost: "$$$",
  },
  {
    image: ai204,
    alt: "Facetune",
    title: "Facetune",
    description: "Facetune, the top selfie editor, is created to improve your radiant look in any photo.",
    link: "https://www.facetuneapp.com/",
    category: "Video",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai205,
    alt: "HitPaw Online Video Enhancer",
    title: "HitPaw Online Video Enhancer",
    description: "Enhance low-quality videos effortlessly to 1080P/4K resolution with HitPaw Online Video Enhancer.",
    link: "http://www.hitpaw.net/",
    category: "Video",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai206,
    alt: "TinyWow",
    title: "TinyWow",
    description: "TinyWow offers a variety of practical functions in a single, convenient AI-powered lifestyle tool.",
    link: "https://tinywow.com/",
    category: "Video",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai207,
    alt: "DeepSwap",
    title: "DeepSwap",
    description: "DeepSwap enables quick creation of captivating face swap videos/images, enhancing visual projects with entertainment.",
    link: "https://www.deepswap.ai/",
    category: "Video",
    rating: 0,
    price: "Paid",
    cost: "$",
  },
  //AUDIO
  {
    image: ai208,
    alt: "Eleven Labs",
    title: "Eleven Labs",
    description: "Eleven Labs uses AI to transform music and sound design, creating innovative auditory experiences.",
    link: "https://elevenlabs.io/",
    category: "Audio",
    rating: 5,
    price: "Trial",
    cost: "$$",
  },
  {
    image: ai209,
    alt: "AI Transcription by Riverside",
    title: "AI Transcription by Riverside",
    description: "iverside's AI Transcription employs advanced technology for precise audio and video transcription, improving content accessibility.",
    link: "https://riverside.fm/",
    category: "Audio",
    rating: 0,
    price: "Free",
    cost: "$",
  },
//mix
  {
    image: ai210,
    alt: "Get Human",
    title: "Get Human",
    description: "AI Phone Assistant stays on hold until answered, saving your time and eliminating waiting.",
    link: "https://gethuman.com/",
    category: "Communication",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai211,
    alt: "Songtell",
    title: "Songtell",
    description: "Songtell presents over 20,000 AI-generated insights and posters, revealing deeper meanings in your favorite songs.",
    link: "https://www.songtell.com/",
    category: "AI Music",
    rating: 0,
    price: "Trial",
    cost: "$",
  },
  {
    image: ai212,
    alt: "Otter AI",
    title: "Otter AI",
    description: "Otter AI captures live meeting recordings to generate instant notes and automated summaries, streamlining information sharing.",
    link: "https://otter.ai/",
    category: "Video",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai213,
    alt: "Crystal",
    title: "Crystal",
    description: "Crystal transforms interactions by personalizing suggestions based on individual personality traits, enhancing emails, calls, and meetings.",
    link: "https://crystals.ai/",
    category: "Communication",
    rating: 0,
    price: "Paid",
    cost: "$$$",
  },
  {
    image: ai214,
    alt: "Topaz Photo AI",
    title: "Topaz Photo AI",
    description: "Topaz Photo AI employs AI to refine images, sharpening edges, reducing noise, and boosting resolution for superior quality.",
    link: "https://www.topazlabs.com/topaz-photo-ai",
    category: "Image",
    rating: 4,
    price: "Paid",
    cost: "$$$",
  },
  {
    image: ai215,
    alt: "AI Meal Planner",
    title: "AI Meal Planner",
    description: "AI Meal Planner creates customized, nutritious meal plans suited to your dietary preferences and needs.",
    link: "https://ai-meal-planner.vercel.app/",
    category: "AI Nutrition",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai216,
    alt: "Landr",
    title: "Landr",
    description: "Landr is a comprehensive music production platform featuring AI-based mastering engines, offering over $3000 worth of plugins, samples, and essentials.",
    link: "https://www.landr.com/.",
    category: "AI Music",
    rating: 2,
    price: "Paid",
    cost: "$",
  },
  {
    image: ai217,
    alt: "Klaviyo SMS Assistant",
    title: "Klaviyo SMS Assistant",
    description: "The Klaviyo SMS Assistant harnesses AI to elevate marketing campaigns with impactful, easily crafted SMS messages.",
    link: "https://www.klaviyo.com/",
    category: "Communication",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai218,
    alt: "Roast Dating",
    title: "Roast Dating",
    description: "Roast Dating leverages AI tech to amplify dating profiles, with smart algorithms augmenting online experiences for more matches and greater satisfaction.",
    link: "https://roast.dating/",
    category: "Dating",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai219,
    alt: "Resume Worded",
    title: "Resume Worded",
    description: "Resume Worded uses AI to provide tailored recommendations for resumes and LinkedIn profiles, helping people craft standout job applications.",
    link: "https://resumeworded.com/",
    category: "Career",
    rating: 4,
    price: "Free",
    cost: "$",
  },
  {
    image: ai220,
    alt: "Civitai",
    title: "Civitai",
    description: "Civitai contributes to fostering AI art creativity by facilitating free and open sharing of models within the community.",
    link: "https://civitai.com/",
    category: "Free",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai221,
    alt: "DoNotPay",
    title: "DoNotPay",
    description: "DoNotPay, a groundbreaking app dubbed the initial robot attorney, empowers users to effortlessly challenge corporations and bureaucracy.",
    link: "http://donotpay.com/",
    category: "Legal",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai222,
    alt: "Namelix",
    title: "Namelix",
    description: "Namelix uses AI to generate catchy, brandable business names in a concise format..",
    link: "https://namelix.com/",
    category: "Content Creation",
    rating: 0,
    price: "Free",
    cost: "$",
  },
  {
    image: ai223,
    alt: "Replika",
    title: "Replika",
    description: "Replika, a comforting AI companion, actively listens, converses, and empathizes to provide support.",
    link: "https://replika.com/",
    category: "Companion",
    rating: 0,
    price: "Free",
    cost: "$",
  },
]);

export default initialCardsData;
