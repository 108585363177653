import React from 'react';

const Star = ({ rating }) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <span
        key={i}
        style={{
          color: i <= rating ? 'gold' : 'gray',
          fontSize: '1.5rem',
          marginRight: '2px',
        }}
      >
        &#9733;
      </span>
    );
  }
  return <div style={{ display: 'flex' }}>{stars}</div>;
};

export default Star;
