import React, { useState } from "react";
import "./Navbar.css";
import logo from "../../assets/logo.png";
import popupVideo from "../../assets/.MP4/video.mp4";

const Navbar = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isSecondPopupOpen, setSecondPopupOpen] = useState(false);
  const [aiToolData, setAiToolData] = useState({
    toolName: "",
    websiteUrl: "",
    g2ReviewUrl: "",
    toolDescription: "",
    yourName: "",
    yourEmail: "",
  });

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };
  const openSecondPopup = () => {
    setSecondPopupOpen(true);
    closePopup();
  };

  const closeSecondPopup = () => {
    setSecondPopupOpen(false);
  };
  
  const handleSubmission = async () => {
    try {
      const response = await fetch("https://aitool-8j7h.onrender.com/addformdata", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(aiToolData),
      });

      if (response.ok) {
        console.log("Submission successful!");
        alert("Submission successful!");
      } else {
        console.error("Submission failed!");
        if (response.status === 400) {
          alert("Invalid data. Please check your input.");
        } else {
          alert("Submission failed. Please try again later.");
  
        }
      }
    } catch (error) {
      console.error("Error during submission:", error);
      alert("An error occurred during submission. Please try again later.");
      
    } finally {
      setAiToolData({
        toolName: "",
        websiteUrl: "",
        g2ReviewUrl: "",
        toolDescription: "",
        yourName: "",
        yourEmail: "",
      });
      closePopup();
    }
  };

  const handleInputChange = (field, value) => {
    setAiToolData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light bg-white ">
        <div className="container-fluid navbar-custom">
          <div className="d-flex align-items-center">
            <a className="navbar-brand logo-custom">
              <img
                src={logo}
                alt="Logo"
                className="d-inline-block align-text-top me-2"
              />
            </a>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-grow-1">
            <span
              className="navbar-brand"
              style={{ fontWeight: 700, fontSize: "25px", marginTop: "15px" }}
            >
              Hubit AI
            </span>
          </div>
          <div className="d-flex">
            <button
              className="btn btn-outline-primary custom-button"
              type="button"
              onClick={openPopup}
            >
              Get Noticed
            </button>
          </div>
        </div>
      </nav>

      {isPopupOpen && (
        <div className="popupOverlay">
          <div className="popup">
            <span className="popupCloseIcon" onClick={closePopup}>
              &times;
            </span>
            <div className="popupContent">
              {/* Left side of the popup */}
              <h2 className="mainheading">Featured List</h2>
              <div className="featureList">
                <div className="featureItem">
                  <h3 className="featureHeading">Hot Spot</h3>
                  <p>Placed in the top row for 7 days</p>
                </div>
                <div className="featureItem">
                  <h3 className="featureHeading">Speedy</h3>
                  <p>Listed within 24hrs</p>
                </div>
                <div className="featureItem">
                  <h3 className="featureHeading">ShowCase</h3>
                  <p>Social media Shout Out on all active platforms</p>
                </div>
              </div>

              {/* Right side of the popup */}
              <div className="rightContent">
                <video autoPlay loop playsInline className="popupVideo">
                  {/* Provide a direct link to the video file */}
                  <source src={popupVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <h2 className="boostHeading">
                  Boost Your AI Tools Visibility!
                </h2>
                <div className="maincontent">
                  <h1>
                    $54<sup>99</sup>
                  </h1>
                  <p>
                    Be a part of our handpicked collection
                    <br /> of AI tools, where you won't have to wait
                    <br />
                    in line, for exposure, to a wider audience.
                  </p>
                </div>
                <button className="promoteButton" onClick={openSecondPopup}>
                  PROMOTE NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isSecondPopupOpen && (
        <div className="secondPopupOverlay">
          <div className="secondPopup">
            <span className="secondPopupCloseIcon" onClick={closeSecondPopup}>
              &times;
            </span>
            {/* Inside the second popupContent */}
            <div className="secondPopupContent">
              <h1 className="secondPopupHeading" style={{fontSize : '30px' , textAlign : 'center' , marginBottom : '30px'}}>Submit Your AI Tool</h1>

              {/* First row */}
              <div className="inputRow">
                <input
                  type="text"
                  className="inputField"
                  placeholder="AI Tool Name"
                  value={aiToolData.toolName}
                  onChange={(e) => handleInputChange("toolName", e.target.value)}
                />
              </div>

              {/* Second row */}
              <div className="inputRow">
                <input
                  type="text"
                  className="inputField"
                  placeholder="Website URL"
                  value={aiToolData.websiteUrl}
                  onChange={(e) => handleInputChange("websiteUrl", e.target.value)}
                />
                <input
                  type="text"
                  className="inputField"
                  placeholder="G2 Review URL"
                  value={aiToolData.g2ReviewUrl}
                  onChange={(e) => handleInputChange("g2ReviewUrl", e.target.value)}
                />
              </div>

              {/* Third row */}
              <div className="inputRow">
                <input
                  type="text"
                  className="inputField"
                  placeholder="AI Tool Description"
                  value={aiToolData.toolDescription}
                  onChange={(e) => handleInputChange("toolDescription", e.target.value)}
                />
              </div>

              {/* Fourth row */}
              <div className="inputRow">
                <input
                  type="text"
                  className="inputField"
                  placeholder="Your Name"
                  value={aiToolData.yourName}
                  onChange={(e) => handleInputChange("yourName", e.target.value)}
                />
                <input
                  type="text"
                  className="inputField"
                  placeholder="Your Email"
                  value={aiToolData.yourEmail}
                  onChange={(e) => handleInputChange("yourEmail", e.target.value)}
                />
              </div>

              {/* Submit button */}
              <button className="submitBtn" onClick={handleSubmission}>
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
