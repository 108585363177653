
import './App.css';
import Card from './Components/Card/Card';
import Header from './Components/Header/Header';
import Navbar from './Components/Navbar/Navbar';

function App() {
  return (
    <div className="App">
     <Navbar/>
     <Header/>
     <br/>
     <Card/>
    </div>
  );
}

export default App;
