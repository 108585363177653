import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import initialCardsData from "../data";
import StarRating from "../Start/Start";
import "./Card.css";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.2,
      transition: { duration: 0.9 },
    },
  },
};

const item = {
  hidden: { x: 0, opacity: 1 },
  visible: {
    x: 10,
    opacity: 5,
    transition: { duration: 0.9 },
  },
};
const categories = [
  '',
  'Writing',
  'SEO',
  'Image',
  'Video',
  'Audio',
  'ChatBot',
  'Data',
  'Web Design',
  'Productivity',
  'Media',
  'Business',
  'Education',
  'Innovations',
  'Design',
  'Coding',
  'Query',
  'Communication',
];

const Card = () => {
  const [cardsData, setCardsData] = useState(initialCardsData);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const controls = useAnimation();

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    if (!searchValue) {
      resetFilters();
    } else {
      filterCards(searchValue, selectedCategory);
    }
  };

  const handleCategoryFilter = (category) => {
    setSelectedCategory(category);
    filterCards(searchTerm, category);
  };

  const filterCards = (search, category) => {
    const filteredCards = initialCardsData.filter(
      (card) =>
        card.title.toLowerCase().includes(search.toLowerCase()) &&
        (category === "" || card.category.trim() === category.trim())
    );
    setCardsData(filteredCards);
  };

  const resetFilters = () => {
    setSearchTerm("");
    setSelectedCategory("");
    setCardsData(initialCardsData);
  };

  useEffect(() => {
    // Trigger the animation when the component mounts
    controls.start("visible");
  }, [controls]);
  const TruncateDescription = ({ description, maxLength }) => {
    if (description.length > maxLength) {
      return <>{description.substring(0, maxLength)} ...</>;
    }
    return <>{description}</>;
  };

  return (
    <div className="container container-custom">
      <div className="mb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search by title..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <div className="d-flex justify-content-center mb-3">
      <div className="btn-group d-none d-lg-flex">
        {/* Render buttons on large screens */}
        {categories.map((category, index) => (
          <button
            key={index}
            type="button"
            className={`btn ${
              selectedCategory === category ? 'custombutton' : 'btn-secondary'
            }`}
            onClick={() => handleCategoryFilter(category)}
          >
            {category || 'All'}
          </button>
        ))}
      </div>
      <div className="dropdown d-lg-none">
        {/* Render dropdown on small screens */}
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="categoryDropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Categories
        </button>
        <div className="dropdown-menu" aria-labelledby="categoryDropdown">
          {categories.map((category, index) => (
            <button
              key={index}
              className={`dropdown-item ${
                selectedCategory === category ? 'custombutton' : ''
              }`}
              onClick={() => handleCategoryFilter(category)}
            >
              {category || 'All'}
            </button>
          ))}
        </div>
      </div>
    </div>

      <motion.div
        className="container py-5"
        variants={container}
        initial="hidden"
        animate={controls}
      >
        <h1 className="text-center">Popular AI Tools</h1>
        <div className="row row-cols-1 row-cols-md-3 g-4 py-5">
          {cardsData.map((card, index) => (
            <motion.div variants={item} key={index} className="col">
              <div className="card">
                <img src={card.image} className="card-img-top custom-image"  alt={card.alt} />
                <div className="card-body d-flex justify-content-between">
                  <h5 className="card-title">{card.title}</h5>
                  <span className="card-text" style={{fontWeight : 500}}>{card.category}</span>
                  
                </div>
                <div className="card-body">
                  <p className="card-text"><TruncateDescription description={card.description} maxLength={120} /></p>
                </div>
                <div className="mb-3 d-flex justify-content-around">
                  <StarRating rating={card.rating} />
                  <a href={card.link} className="btn btn-primary custom-button">
                    Visit Website
                  </a>
                 
                </div>
                <div className="card-price">{card.cost}</div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Card;
