import React from 'react';
import ai from '../../assets/.PNG/ai.png';
import './Header.css';
import { FaYoutube, FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';

const Header = () => (
  <>
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-image">
        <img src={ai} alt="AI" />
      </div>
      <div className="gpt3__header-content">
        <h1 className="gradient__text">Join Our Vibrant Community without <br/>the hassle of email sign-ups</h1>
        <p style={{color: 'white'}}>Connect instantly by following us on social media. Click any of the symbols<br/> below to follow us and stay updated with our latest content and join the<br/> conversation today.</p>
        <div className="social-icons">
          {/* <a href='https://www.youtube.com/@Hubitai' target='_blank' rel="youtube"><FaYoutube className="icon" color='white'/></a> */}
          <a href=' https://www.facebook.com/hubitai/' target='_blank' rel="facebook"> <FaFacebook className="icon" color='white'/></a>
          <a href='https://www.linkedin.com/company/hubit-ai' target='_blank' rel="linkendin"> <FaLinkedin className="icon" color='white'/></a>
          <a href='https://twitter.com/Hubit_AI' target='_blank' rel="twitter"><FaTwitter className="icon" color='white'/></a>
        </div>
      </div>
    </div>
  </>
);

export default Header;
